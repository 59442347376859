import React from "react";
import styled from "styled-components";

import Header from "../components/header";

const Set01 = styled.div`
  height: 360px;
  width: 100%;
  object-fit: cover;
`

const Set02 = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
`

const Main = styled.div`
  width: 70%;
  height: 100%;
`

const CoverImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: solid 1px white;
`

const Sub = styled.div`
  width: 30%;
  height: 100%;
`

function Detail() {
  return (
    <div>
      <Header />
      <Set01>
        {/* https://unsplash.com/photos/2d4lAQAlbDA */}
        <CoverImage src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80" />
      </Set01>
      <Set02>
        <Main>
          {/* https://unsplash.com/photos/MP0bgaS_d1c */}
          <CoverImage src="https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1653&q=80" />
        </Main>
        <Sub>
          {/* https://unsplash.com/photos/OtXADkUh3-I */}
          <CoverImage src="https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80" />
        </Sub>
      </Set02>
      <Set02>
        <Sub>
          {/* https://unsplash.com/photos/UV81E0oXXWQ */}
          <CoverImage src="https://images.unsplash.com/photo-1502005229762-cf1b2da7c5d6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80" />
        </Sub>
        <Main>
          {/* https://unsplash.com/photos/hHz4yrvxwlA */}
          <CoverImage src="https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80" />
        </Main>
      </Set02>
      <Set01>
        {/* https://unsplash.com/photos/R-LK3sqLiBw */}
        <CoverImage src="https://images.unsplash.com/photo-1501183638710-841dd1904471?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80" />
      </Set01>
    </div>
  )
}

export default Detail